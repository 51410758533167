import { useState, useEffect } from "react";
import { ethers } from "ethers";

import Config from "../config";

export default function useLpData(
    lpContract,
    address,
    getERC20Contract,
    getPairContract
) {
    const [allLocksLength, setAllLocksLength] = useState(0);
    const [userLocksLength, setUserLocksLength] = useState(0)

    /// @param Token names
    const [allLocks, setAllLocks] = useState([]);

    /// @param Token names
    /// @param idx: lock index
    /// @param userLpBalance
    /// @param lockData
        /// lockDate
        /// amount
        /// initialAmount
        /// unlockDate
        /// lockID
        /// owner
    const [userLocks, setUserLocks] = useState([]);

    /// @param Locked LP Tokens Amount
    /// @param Total LP Tokens Amount
    /// @param Unlock schedule
        /// list of % being unlocked when
    /// @param Token names
    const [lockAggregates, setLockAggregates] = useState({})

    async function fetchLockAggregate(lpAddress) {
        try { 
            let pairContract = getPairContract(lpAddress);

            let token0Contract = getERC20Contract(await pairContract.token0());
            let token1Contract = getERC20Contract(await pairContract.token1());

            let token0Name = await token0Contract.symbol();
            let token1Name = await token1Contract.symbol();

            let totalLocks = (await lpContract.getNumLocksForToken(lpAddress)).toNumber();
            let locks = [];
            let totalLockedAmount = ethers.BigNumber.from(0);
            let totalLpTokens = await pairContract.totalSupply();

            for (let i = 0; i < totalLocks; i++) {
                let lock = await lpContract.tokenLocks(lpAddress, i);
                locks.push(lock)
                totalLockedAmount = totalLockedAmount.add(lock.amount);
            }

            let unlockingSchedule = [];
            for (let i = 0; i < totalLocks; i++) {
                let lock = locks[i];
                let lockShare = totalLockedAmount.gt(0) ? lock.amount.mul(100).div(totalLockedAmount) : ethers.BigNumber.from(0);
                let unlockDate = lock.unlockDate;
                unlockingSchedule.push({
                    lockShare,
                    unlockDate
                })
            }

            return {
                token0Name,
                token1Name,
                totalLockedAmount,
                totalLpTokens,
                unlockingSchedule
            }
        } catch (e) {
            console.log(e)
        }
    }

    async function fetchLpTokenData(lpAddress) {
        try {
            let pairContract = getPairContract(lpAddress);

            let token0Contract = getERC20Contract(await pairContract.token0());
            let token1Contract = getERC20Contract(await pairContract.token1());

            let token0Name = await token0Contract.symbol();
            let token1Name = await token1Contract.symbol();

            return {
                lpAddress,
                token0Name,
                token1Name
            }
        } catch {
            return false;
        }
    }

    async function fetchUserLpBalance(lpAddress) {
        let lpTokenContract = getERC20Contract(lpAddress);
        let balance = await lpTokenContract.balanceOf(address);
        return ethers.utils.formatEther(balance);
    }

    async function getAllLockedTokens() {
        setAllLocks([]);
        let lockedTokensAmount = (await lpContract.getNumLockedTokens()).toNumber();
        setAllLocksLength(lockedTokensAmount);
        
        let allLocksArray = []
        for (let i = 0; i < lockedTokensAmount; i++) {
            let lockedLpAddress = await lpContract.getLockedTokenAtIndex(i);
            let lpTokenData = await fetchLpTokenData(lockedLpAddress);
            allLocksArray.push({
                lp: lockedLpAddress,
                token0: lpTokenData.token0Name,
                token1: lpTokenData.token1Name
            })            
        }
        setAllLocks(allLocksArray);
    }

    async function getUserLockedTokens() {
        setUserLocks([]);
        let lockedTokensAmount = (await lpContract.getUserNumLockedTokens(address)).toNumber();
        setUserLocksLength(lockedTokensAmount);

        let userLocksArray = []
        for (let i = 0; i < lockedTokensAmount; i++) {            
            try {
                let lockedLpAddress = await lpContract.getUserLockedTokenAtIndex(address, i);
                let locksForSpecificToken = (await lpContract.getUserNumLocksForToken(address, lockedLpAddress)).toNumber();
                let lpTokenData = await fetchLpTokenData(lockedLpAddress);
                let userLpBalance = await fetchUserLpBalance(lockedLpAddress)
                
                for (let j = 0; j < locksForSpecificToken; j++) {
                    let lockData = await lpContract.getUserLockForTokenAtIndex(
                        address,
                        lockedLpAddress,
                        j
                    )
                    userLocksArray.push({
                        idx: j,
                        lp: lockedLpAddress,
                        token0: lpTokenData.token0Name,
                        token1: lpTokenData.token1Name,
                        lockData,
                        userLpBalance
                    })
                }                
            } catch (e) {
                console.log(e)
            }
        }
        setUserLocks(userLocksArray);
    }

    useEffect(() => {
        if (lpContract && address) {
            getAllLockedTokens();
            getUserLockedTokens();
        }
    }, [lpContract, address])

    return [
        allLocks,
        userLocks,
        lockAggregates,
        fetchLockAggregate,
        fetchLpTokenData,
        fetchUserLpBalance,
        getUserLockedTokens,
        getAllLockedTokens
    ]
}
