import { NavLink } from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";

import "../../styles/Header.css";
import { useState, useEffect, useCallback } from "react";

const Header = () => {
    const [headerExtraStyle, setHeaderExtraStyle] = useState({});

    return (
        <header className={`header-wrapper`} style={headerExtraStyle}>
            <div className="logo">
                <img
                    src="https://static.wixstatic.com/media/dea72f_64023159771f4b93a814fb8f0b00d73c~mv2.gif"
                    alt="logo"
                />
                <a className="title" href="https://www.grimacecoincrypto.com">
                    GrimaceCoin
                </a>
            </div>
            <div className="menu-container">
                <NavLink end to="/">
                    {({ isActive }) =>
                        isActive ? (
                            <span className="menu selected">Locker</span>
                        ) : (
                            <span className="menu">Locker</span>
                        )
                    }
                </NavLink>
                <NavLink to="/staking">
                    {({ isActive }) =>
                        isActive ? (
                            <span className="menu selected">Staking</span>
                        ) : (
                            <span className="menu">Staking</span>
                        )
                    }
                </NavLink>
            </div>
        </header>
    );
};

export default Header;
