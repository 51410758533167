import "../../styles/CrimaceForm.css";
import withHeader from "../../hoc/withHeader";
import Form from "./Form";
import { useEffect, useState } from "react";
import ManageTokens from "./ManageTokens";
import BrowseTokens from "./BrowseTokens";
import { ethers } from "ethers";

import useWeb3 from "../../hooks/useWeb3";
import useLpData from "../../hooks/useLpData";
import useLock from "../../hooks/useLock";

const CrimaceForm = () => {
    const [page, setPage] = useState(1);
    const [showCertificate, setShowCertificate] = useState(false);
    const [certificateLpAddress, setCertificateLpAddress] = useState("");

    const [
        provider,
        signer,
        address,
        contracts,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect,
        isConnecting,
        getERC20Contract,
        getPairContract,
        chainId,
    ] = useWeb3();

    const [
        allLocks,
        userLocks,
        lockAggregates,
        fetchLockAggregate,
        fetchLpTokenData,
        fetchUserLpBalance,
        getUserLockedTokens,
        getAllLockedTokens,
    ] = useLpData(contracts.LP, address, getERC20Contract, getPairContract);

    const [approveLock, lock, reLock, withdraw, addToLock, transferLockOwnership, getApproval] = useLock(
        contracts.LP,
        signer,
        getERC20Contract,
        getUserLockedTokens,
        getAllLockedTokens
    );

    const navigate = (page) => {
        setPage(page);
    };

    const handleSubmit = (e) => {
        console.log(e);
        e.preventDefault();
        //const formData = new FormData(e.target);
        //const data = Object.fromEntries(formData);
        //Object.entries(data).forEach(([key, value]) => {
        //  console.log(`${key}: ${value}`);
        //  if (!value) {
        //    alert(`Please fill out all fields, ${key} is missing`);
        //    return;
        //  }
        //});
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let lpAddress = params.get("lp");
        if (lpAddress && ethers.utils.isAddress(lpAddress)) {
            setShowCertificate(true);
            setPage(3);
            connect().then((_) => {
                setCertificateLpAddress(lpAddress);
            });
        }
    }, []);

    return (
        <main className="crimace-swipe-container">
            <div className="grimace-page-header">
                <img
                    src="/7af1f908-a9cf-4a76-9ae3-22199fa34418.png"
                    alt="img"
                    style={{ height: "150px" }}
                />
                {!isConnected ? (
                    <button className="crimace-swipe-button" onClick={connect}>
                        Connect Wallet
                    </button>
                ) : null}
            </div>
            <div className="form-container">
                <div className="form-nav">
                    <button
                        onClick={() => navigate(1)}
                        type="button"
                        className={`form-nav-item ${page === 1 ? "active" : ""}`}
                    >
                        Lock LP Tokens
                    </button>
                    <button
                        onClick={() => navigate(2)}
                        type="button"
                        className={`form-nav-item ${page === 2 ? "active" : ""}`}
                    >
                        Manage LP Token Locks
                    </button>
                    <button
                        onClick={() => navigate(3)}
                        type="button"
                        className={`form-nav-item ${page === 3 ? "active" : ""}`}
                    >
                        Browse All Locks
                    </button>
                </div>
                {page === 1 && (
                    <Form
                        address={address}
                        handleSubmit={handleSubmit}
                        fetchLpTokenData={fetchLpTokenData}
                        fetchUserLpBalance={fetchUserLpBalance}
                        approveLock={approveLock}
                        getApproval={getApproval}
                        lock={lock}
                        isConnected={isConnected}
                        chainId={chainId}
                    />
                )}
                {page === 2 && (
                    <ManageTokens
                        isConnected={isConnected}
                        userLocks={userLocks}
                        withdraw={withdraw}
                        reLock={reLock}
                        addToLock={addToLock}
                        transferLockOwnership={transferLockOwnership}
                        approveLock={approveLock}
                        chainId={chainId}
                    />
                )}
                {page === 3 && (
                    <BrowseTokens
                        allLocks={allLocks}
                        showCertificate={showCertificate}
                        certificateLpAddress={certificateLpAddress}
                        fetchLockAggregate={fetchLockAggregate}
                    />
                )}
            </div>
        </main>
    );
};

export default withHeader(CrimaceForm);
