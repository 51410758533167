import PropTypes from "prop-types";
import { useState } from "react";
import DatePicker from "react-datepicker";

const FormElement = ({
    id = "",
    label = "",
    type = "text",
    name = "",
    placeholder = "",
    endText = "",
    endAdornment,
    onChange,
    isDisabled,
    value,
}) => {
    return (
        <div className="form-element">
            {label && <label htmlFor={id}>{label}</label>}
            <div className="form-element-input">
                {type === "date" ? (
                    <DatePicker
                        dateFormat={"dd-MM-yyyy"}
                        placeholderText={"dd-mm-yyyy"}
                        selected={value}
                        onSelect={onChange}
                        disabled={isDisabled}
                        value={value}
                        onChange={onChange}
                    />
                ) : (
                    <input
                        type={type}
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        onChange={onChange}
                        disabled={isDisabled}
                        value={value}
                    />
                )}
                {endAdornment ? <div className="end-adornment">{endAdornment}</div> : <></>}
                {endText && <div className="end-text">{endText}</div>}
            </div>
        </div>
    );
};

export default FormElement;

FormElement.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    endAdornment: PropTypes.node,
    endText: PropTypes.string,
};
