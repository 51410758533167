import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai';
import { useState } from 'react';
import { ethers } from 'ethers';

import InputField from "./components/InputField";
import InputRadio from "./components/InputRadio";

import { format, addDays } from 'date-fns'

import Config from "../../config";

const ManageTokens = ({
    chainId,
    isConnected,
    userLocks,
    withdraw,
    reLock,
    addToLock,
    transferLockOwnership,
    approveLock
}) => {
    const [activeLock, setActiveLock] = useState();
    const [activeSection, setActiveSection] = useState();

    // Withdraw
    const [withdrawAmount, setWithdrawAmount] = useState(0)

    const getWithdrawAmount = (unlockDate, withdrawAmount) => {
        let now = Math.floor(Date.now() / 1000);
        if (now > unlockDate)
            return parseFloat(ethers.utils.formatEther(withdrawAmount)).toString()
        else
            return 0;
    }

    const withdrawAction = async (
        lpAddress,
        lockIndex,
        lockId
    ) => {
        await withdraw(
            lpAddress,
            lockIndex,
            lockId,
            ethers.utils.parseEther(withdrawAmount)
        )
    }

    // Relock (date)
    const [relockDate, setRelockDate] = useState()

    function returnDateObject(str) {
        if (typeof str === "string") {
            let dateComponents = str.split("-");
            return new Date(dateComponents[1] + "-" + dateComponents[0] + "-" + dateComponents[2]);
        } else
            return str
    }

    const reLockAction = async (
        lpAddress,
        lockIndex,
        lockId,
        prevDate
    ) => {
        let dateObj = returnDateObject(relockDate); 
        let unixTime = Math.floor(dateObj.getTime() / 1000)
        if (unixTime < prevDate) {
            alert(`Submitted date must be more than current unlock date`)
            return;
        }
        await reLock(
            lpAddress,
            lockIndex,
            lockId,
            unixTime
        )
    }   

    // Lock more tokens
    const [addLockAmount, setAddLockAmount] = useState(0);
    const [feeInBnb, setFeeInBnb] = useState(true)

    const addLockAction = async (
        lpAddress,
        lockIndex,
        lockId,       
    ) => {                
        await addToLock(
            lpAddress,
            lockIndex,
            lockId,
            ethers.utils.parseEther(addLockAmount),
            feeInBnb === "true",
            {
                value: feeInBnb === "true" ? ethers.utils.parseEther(Config.DATA.FEE[chainId].amount) : undefined
            }
        )
    }
    async function approveLp(lpAddress) {
        if (ethers.utils.isAddress(lpAddress)) {
            await approveLock(lpAddress, ethers.utils.parseEther(addLockAmount))
        } else
            alert("Invalid LP address");
    }

    // Transfer Ownership
    const [newOwner, setNewOwner] = useState("");
    const transferOwnerAction = async (
        lpAddress,
        lockIndex,
        lockId,       
    ) => {
        await transferLockOwnership(
            lpAddress,
            lockIndex,
            lockId,
            newOwner
        )
    }

    if (!isConnected)
        return <div>Please Connect a wallet</div>;
    else
        return (
            <div>
                {
                    userLocks && userLocks.length > 0 ? userLocks.map((lock, i) => (
                        <div style={{cursor: "pointer"}}>
                            <div onClick={() => setActiveLock(_ => _ === i ? null : i)}>
                                {
                                    activeLock === i ?
                                        <AiFillCaretDown />
                                    :
                                        <AiFillCaretRight />
                                } Lock {i + 1} ({lock.token0}/{lock.token1})
                            </div>
                            {
                                activeLock === i ?
                                <div className='crimace-browse-container'>
                                    <p>LP Token: {lock.token0}/{lock.token1}</p>
                                    <p>Locked LP Tokens: {
                                        parseFloat(
                                            ethers.utils.formatEther(lock.lockData[1].toString())
                                        ).toString()} </p>
                                    <p>Unlock time: {new Date(Math.floor(lock.lockData[3].toNumber() * 1000)).toString()}</p>
                                    <div style={{width: "75%"}}>
                                        <button
                                            onClick={() => setActiveSection(1)}
                                            className="crimace-swipe-button-small" type="submit" id="Lock" >
                                            Withdraw Unlocked Tokens
                                        </button>
                                        <button
                                            onClick={() => setActiveSection(2)}
                                            className="crimace-swipe-button-small" type="submit" id="Lock" >
                                            Increase Lock Time
                                        </button>
                                        <button
                                            onClick={() => setActiveSection(3)}
                                            className="crimace-swipe-button-small" type="submit" id="Lock" >
                                            Lock More Tokens
                                        </button>
                                        <button
                                            onClick={() => setActiveSection(4)}
                                            className="crimace-swipe-button-small" type="submit" id="Lock" >
                                            Transfer Ownership
                                        </button>
                                        <button
                                            onClick={() => setActiveSection(5)}
                                            className="crimace-swipe-button-small" type="submit" id="Lock" >
                                            Generate Public Link
                                        </button>

                                         <form className="crimace-swipe-form" style={{marginTop: "30px", marginBottom: "10px"}} onSubmit={(e) => e.preventDefault()}>
                                            {
                                                activeSection === 1 ? 
                                                    <div>
                                                        <InputField
                                                            type="number"
                                                            name="token-amount"
                                                            id="token-amount"
                                                            placeholder="Enter LP token amount to withdraw"
                                                            endText={`Available: ${getWithdrawAmount(lock.lockData[3], lock.lockData[1])}`}                                                       
                                                            endAdornment={
                                                                <button 
                                                                    className="end-adornment-button"   
                                                                    onClick={() => setWithdrawAmount(getWithdrawAmount(lock.lockData[3], lock.lockData[1]))}                                                              
                                                                >Max</button>
                                                            }
                                                            value={withdrawAmount}
                                                            onChange={(e) => setWithdrawAmount(e.target.value)}                                         
                                                        />
                                                        <button
                                                            style={{marginTop: "10px"}}
                                                            onClick={() => withdrawAction(lock.lp, lock.idx, lock.lockData[4])}
                                                            className="crimace-swipe-button-small" type="submit" id="Lock" >
                                                            Withdraw
                                                        </button>
                                                    </div>
                                                :
                                                activeSection === 2 ? 
                                                    <div>
                                                       <InputField
                                                            id="manage-lp"
                                                            name="manage-lp"
                                                            type="date"
                                                            endAdornment={
                                                                <>
                                                                    <button 
                                                                        className="end-adornment-button"  
                                                                        onClick={() => setRelockDate(_ => {         
                                                                            if (!_) {                                    
                                                                                return format(addDays(new Date(), 1), "d-M-Y")
                                                                            } else {
                                                                                let dateObj = returnDateObject(_)
                                                                                return format(addDays(dateObj, 1), "d-M-Y")
                                                                            }                                
                                                                        })}                                                                      
                                                                    >Tomorrow</button>
                                                                    <button 
                                                                        className="end-adornment-button"  
                                                                        onClick={() => setRelockDate(_ => {
                                                                            if (!_)
                                                                                return _;
                                                                            let dateObj = returnDateObject(_)
                                                                            return format(addDays(dateObj, 365), "d-M-Y")                            
                                                                        })}                                                                      
                                                                    >+1 year</button>
                                                                </>
                                                            }
                                                            value={relockDate}
                                                            onChange={(e) => setRelockDate(e)}                                                      
                                                        />
                                                        <button
                                                            style={{marginTop: "10px"}}
                                                            onClick={() => reLockAction(lock.lp, lock.idx, lock.lockData[4], lock.lockData[3])}
                                                            className="crimace-swipe-button-small" type="submit" id="Lock" >
                                                            Extend
                                                        </button>
                                                </div>
                                                :
                                                activeSection === 3 ? 
                                                    <div>
                                                        <InputField
                                                            type="number"
                                                            name="token-amount"
                                                            id="token-amount"
                                                            placeholder="Enter LP token amount to lock"
                                                            endText={`Available: ${
                                                                parseFloat(
                                                                    lock.userLpBalance
                                                                ).toString()
                                                            }`}                                                       
                                                            endAdornment={
                                                                <button 
                                                                    className="end-adornment-button"
                                                                    onClick={() => setAddLockAmount(parseFloat(
                                                                        lock.userLpBalance
                                                                    ).toString())}                                                               
                                                                >Max</button>
                                                            }
                                                            value={addLockAmount}
                                                            onChange={(e) => setAddLockAmount(e.target.value)}                                                        
                                                        />                                                        
                                                        <InputRadio
                                                            useBaseClass={false}
                                                            label="Select fee type:"
                                                            name="fee-type"
                                                            options={[
                                                                {
                                                                label: (
                                                                    <div>
                                                                    Pay in LP <span>(0.5% of locked LP Tokens)</span>
                                                                    </div>
                                                                ),
                                                                value: false,
                                                                },
                                                                {
                                                                label: (
                                                                    <div>
                                                                    Pay in {Config.DATA.FEE[chainId].currency} <span>({Config.DATA.FEE[chainId].amount} {Config.DATA.FEE[chainId].currency} flat)</span>
                                                                    </div>
                                                                ),
                                                                value: true,
                                                                },
                                                            ]}    
                                                            value={feeInBnb}
                                                            onChange={(e) => setFeeInBnb(e.target.value)}                                                        
                                                        />
                                                        <div className="form-buttons">
                                                            <button
                                                                onClick={() => approveLp(lock.lp)}                                          
                                                                className="crimace-swipe-button" type="submit" id="Approve">
                                                                Approve LP Increase
                                                            </button>
                                                            <button
                                                                onClick={() => addLockAction(lock.lp, lock.idx, lock.lockData[4])}                                                
                                                                className="crimace-swipe-button" type="submit" id="Lock">
                                                                Increase LP Lock
                                                            </button>
                                                        </div>
                                                    </div>
                                                :
                                                activeSection === 4 ? 
                                                    <div>
                                                        <InputField
                                                            name="pancake-swap"
                                                            id="pancake-swap"
                                                            placeholder="Enter new LP Lock owner"
                                                            value={newOwner}
                                                            onChange={(e) => setNewOwner(e.target.value)}                                                                       
                                                        />
                                                        <button
                                                            style={{marginTop: "10px"}}
                                                            onClick={() => transferOwnerAction(lock.lp, lock.idx, lock.lockData[4])}
                                                            className="crimace-swipe-button-small" type="submit" id="Lock" >
                                                            Transfer
                                                        </button>
                                                    </div>
                                                :
                                                activeSection === 5 ? 
                                                    <div><a style={{color: "white"}} href={`/?lp=${lock.lp}`}>Click here to open the Certificate</a></div>
                                                :
                                                    null
                                            }                                     
                                        </form>                                    
                                    </div>                                                                     
                                </div>
                             :
                                null  
                            }
                            
                        </div>
                    ))
                    :
                        null
                }
            </div>
        )
};

export default ManageTokens;
