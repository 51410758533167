import PropTypes from "prop-types";

const WalletCard = ({
  img,
  number,
  coin,
  buttonText,
  onClick,
  footerText,
  tooltip,
}) => {
  return (
    <div className="crimace-card">
      {tooltip && <div className="card-tooltip">{tooltip}</div>}
      <div className="card-details">
        {img && <img src={img} alt="coin" />}
        <div className="card-details-text">
          <div className="card-details-number">{number}</div>
          <div className="card-details-coin">{coin}</div>
        </div>
      </div>
      <div className="card-footer">
        {footerText && <div className="card-footer-text">{footerText}</div>}
        <button className="card-button" onClick={onClick}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default WalletCard;

WalletCard.propTypes = {
  img: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  coin: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  footerText: PropTypes.string,
  tooltip: PropTypes.string,
};
