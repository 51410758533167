import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import InputField from "./components/InputField";
import InputRadio from "./components/InputRadio";

import "react-datepicker/dist/react-datepicker.css";

import { ethers } from "ethers";

import { format, addDays } from "date-fns";

import Config from "../../config";

const Form = ({
    address,
    chainId,
    handleSubmit,
    fetchLpTokenData,
    fetchUserLpBalance,
    approveLock,
    lock,
    isConnected,
    getApproval
}) => {
    const [lpAddress, setLpAddress] = useState("");
    const [token0, setToken0] = useState("---");
    const [token1, setToken1] = useState("---");
    const [userLpBalance, setUserLpBalance] = useState(0);
    const [allowance, setAllowance] = useState()

    const [amount, setAmount] = useState();
    const [date, setDate] = useState();
    const [feeInBnb, setFeeInBnb] = useState("true");
    
    async function fetchLpData(lpAddress) {
        let tokenData = await fetchLpTokenData(lpAddress);
        if (tokenData) {
            setToken0(tokenData.token0Name);
            setToken1(tokenData.token1Name);
        }
    }

    function returnDateObject(str) {
        if (typeof str === "string") {
            let dateComponents = str.split("-");
            return new Date(dateComponents[1] + "-" + dateComponents[0] + "-" + dateComponents[2]);
        } else return str;
    }

    const onAddDay = (numOfDays) => {
        setDate((curr) => {
            if (!curr) return new Date();
            return addDays(curr, numOfDays);
        });
    };

    async function fetchAllowance() {
        if (ethers.utils.isAddress(lpAddress)) {
            let allowance = await getApproval(lpAddress);
            console.log(allowance)
            setAllowance(allowance);
        }
    }

    async function approveLp() {
        if (ethers.utils.isAddress(lpAddress)) {
            await approveLock(lpAddress, ethers.utils.parseEther(amount));
            setAllowance(ethers.utils.parseEther(amount));
        } else alert("Invalid LP address");
    }

    async function lockLp() {
        try {
            let float = parseFloat(amount).toFixed(3);
            let etherAmount = ethers.utils.parseEther(float);
            if (!ethers.utils.isAddress(lpAddress)) {
                alert("Invalid LP Address")
                return
            }
            if (!allowance.gte(etherAmount)) {
                alert("Insufficient allowance. Please press the approve button to fix this.")
                return
            }
            if (!date) {
                alert("Date not set. Please set date.")
                return
            }
             
            // date is already a date Object so no need to convert
            let dateObj = returnDateObject(date);
            await lock(
                lpAddress,
                etherAmount, 
                Math.floor(dateObj.getTime() / 1000),
                feeInBnb === "true",
                address,
                {
                    value:
                        feeInBnb === "true"
                            ? ethers.utils.parseEther(Config.DATA.FEE[chainId].amount)
                            : undefined,
                }
            );

            setLpAddress("");
            setAmount(0);
            setDate("");
            setFeeInBnb(true);
        } catch (e) {
            console.log(e);
        }
    }

    const inputsDisabled = () => {
        return (token0 === "--" && token1 === "--") || !isConnected;
    };
    const lockDisabled = () => {
        return (
            !lpAddress ||
            !amount ||
            !date ||
            !isConnected ||
            !allowance ||
            !allowance.gte(ethers.utils.parseEther(amount))
        );
    };

    useEffect(() => {
        if (ethers.utils.isAddress(lpAddress)) {
            fetchLpData(lpAddress);
            fetchUserLpBalance(lpAddress).then((res) =>
                setUserLpBalance(parseFloat(res).toString())
            );
            fetchAllowance();
        } else {
            if (token0 !== "--") setToken0("---");
            if (token1 !== "--") setToken1("---");
            setUserLpBalance(0);
        }
    }, [lpAddress]);

    return (
        <form className="crimace-swipe-form" onSubmit={handleSubmit}>
            <div className="crimace-swape-form-container">
                <InputField
                    name="pancake-swap"
                    id="pancake-swap"
                    placeholder="Enter PancakeSwap LP token address"
                    endText={`Pair: ${token0}/${token1}`}
                    onChange={(e) => setLpAddress(e.target.value)}
                    value={lpAddress}
                    isDisabled={inputsDisabled()}
                />
                <InputField
                    type="number"
                    name="token-amount"
                    id="token-amount"
                    placeholder="Enter LP token amount to lock"
                    endText={`Available: ${userLpBalance}`}
                    endAdornment={
                        <button
                            className="end-adornment-button"
                            style={inputsDisabled() ? null : { cursor: "pointer" }}
                            disabled={inputsDisabled()}
                            onClick={() => setAmount(userLpBalance)}
                        >
                            Max
                        </button>
                    }
                    isDisabled={inputsDisabled()}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
                <InputField
                    id="manage-lp"
                    name="manage-lp"
                    type="date"
                    endAdornment={
                        <>
                            <button
                                type="button"
                                className="end-adornment-button"
                                disabled={inputsDisabled()}
                                style={inputsDisabled() ? null : { cursor: "pointer" }}
                                onClick={() => onAddDay(1)}
                            >
                                Tomorrow
                            </button>
                            <button
                                type="button"
                                className="end-adornment-button"
                                disabled={inputsDisabled()}
                                style={inputsDisabled() ? null : { cursor: "pointer" }}
                                onClick={() => onAddDay(365)}
                            >
                                +1 year
                            </button>
                        </>
                    }
                    isDisabled={inputsDisabled()}
                    value={date}
                    onChange={(e) => {
                        //console.log(e)
                        //console.log(typeof e)
                        setDate(e);
                    }}
                />
                <InputRadio
                    label="Select fee type:"
                    name="fee-type"
                    options={[
                        {
                            label: (
                                <div>
                                    Pay in LP <span>(0.5% of locked LP Tokens)</span>
                                </div>
                            ),
                            value: false,
                        },
                        {
                            label: (
                                <div>
                                    Pay in {Config.DATA.FEE[chainId].currency}{" "}
                                    <span>
                                        ({Config.DATA.FEE[chainId].amount}{" "}
                                        {Config.DATA.FEE[chainId].currency} flat)
                                    </span>
                                </div>
                            ),
                            value: true,
                        },
                    ]}
                    isDisabled={inputsDisabled()}
                    value={feeInBnb}
                    onChange={(e) => setFeeInBnb(e.target.value)}
                />
                <div className="form-buttons">
                    <button
                        onClick={approveLp}
                        className="crimace-swipe-button"
                        type="submit"
                        id="Approve"
                        disabled={inputsDisabled()}
                    >
                        Approve LP Tokens
                    </button>
                    <button
                        onClick={lockLp}
                        className="crimace-swipe-button"
                        type="submit"
                        id="Lock"
                        disabled={lockDisabled()}
                        style={lockDisabled() ? { cursor: "not-allowed", color: "gray" } : { cursor: "pointer" }}
                    >
                        Lock LP Tokens
                    </button>
                </div>
            </div>
        </form>
    );
};

export default Form;

Form.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};
