import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";

import Loader from "../../components/Loader";
import CertifiedToken from "./components/CertifiedToken";

const BrowseTokens = ({
    allLocks,
    showCertificate,
    certificateLpAddress,
    fetchLockAggregate
}) => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lpData, setLpDate] = useState()

  useEffect(() => {
    if (showCertificate && certificateLpAddress && ethers.utils.isAddress(certificateLpAddress)) {
        fetchLockAggregate(certificateLpAddress).then(res => {
            setLpDate(res);
            console.log(res)
        })
    }
  }, [certificateLpAddress, showCertificate])
  
  const getTokens = useCallback(async () => {
    try {
      setLoading(true);
      const response = await new Promise((resolve) => {
        setTimeout(() => {
          resolve([
            { id: 0, name: "Token 1" },
            { id: 1, name: "Token 2" },
          ]);
        }, 1000);
      });
      setTokens(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    //getTokens();
  }, [getTokens]);

  const getLpDataPercentage = () => {
    if (!lpData)
        return 0;

    let totalLockedTokens = lpData.totalLockedAmount;
    let totalLpTokens = lpData.totalLpTokens;

    if (totalLockedTokens.eq(totalLpTokens)) 
        return 100;

    let oneEth = ethers.utils.parseEther("1")
    let oneEthPercent = ethers.utils.parseEther("0.01")

    let totalLocked = totalLockedTokens.mul(oneEth);
    let totalLp = lpData.totalLpTokens;
    
    if (totalLp.eq(0) || totalLocked.eq(0))
        return 0;


    let ratioStr = totalLocked.div(totalLp).toString();
    let oneEthPercentStr = oneEthPercent.toString();    

    if (ratioStr.length >= oneEthPercentStr.length) {
        let lengthDiff = ratioStr.length - oneEthPercentStr.length + 1;
        return ratioStr.slice(0,lengthDiff) + "." + ratioStr.slice(lengthDiff,4);       
    } else {
        let lengthDiff = oneEthPercentStr.length - ratioStr.length;
        let leadingZeros = "0.".padEnd(lengthDiff + 1, "0");
        return leadingZeros + ratioStr.slice(0,4)
    }    
  }

  if (loading) return <Loader />;

  return (
    <div className="browse-token-container">
      {allLocks && !showCertificate && allLocks.length > 0 ? allLocks.map((lock, i) => (
        <CertifiedToken name={`${lock.token0}/${lock.token1}`} key={i} onClick={() => window.open(`/?lp=${lock.lp}`, "_self")}/>
      )) : null}
      {
        showCertificate ?
            <div className="certificateContainer">
                <h1>Certificate of LP Lock</h1>
                <CertifiedToken name={lpData ? `${lpData.token0Name}/${lpData.token1Name}` : "--/--"} />
                <div>{certificateLpAddress}</div>
                <div>Lp token: {lpData ? lpData.token0Name : "--"}/{lpData ? lpData.token1Name : "--"}</div>
                <div>Locked Percentage: {
                    lpData?
                    getLpDataPercentage()
                    :
                        0
                    }%</div>       
                <div>Amount locked: {
                    lpData?
                        parseFloat(
                            ethers.utils.formatEther(lpData.totalLockedAmount)
                        ).toFixed(12)
                    :
                        0
                }</div>
                <div>Total LP tokens: {
                    lpData?
                        parseFloat(
                            ethers.utils.formatEther(lpData.totalLpTokens)
                        ).toFixed(12)
                    :
                        0
                }</div>
                <div>Unlock schedule: </div>
                <div>{
                    lpData ?
                        lpData.unlockingSchedule.map(schedule => {
                            return (<p style={{fontWeight: "light", fontSize: "10px"}}>{schedule.lockShare.toString()}% unlocks at {new Date(Math.floor(schedule.unlockDate.toNumber() * 1000)).toString()}</p>)
                        })
                    :
                        null
                }</div>
            </div>
        :
            null
      }
      
    </div>
  );
};

export default BrowseTokens;
