import { useState, useEffect } from "react";
import { ethers } from "ethers";

import Config from "../config";

export default function useNftData(
    nftContract,
    stakeContract,
    address,
    allPairs
) {
    const [userNfts, setUserNFts] = useState();
    const [userStakedNfts, setUserStakedNFts] = useState();
    const [userNftApprovals, setUserNftApprovals] = useState({})
    const [userContractApproval, setUserContractApproval] = useState();

    const [claimableLp, setClaimableLp] = useState(0)

    async function getUserNFTs() {
        try {
            const balanceOfUser = (await nftContract.balanceOf(address));
            const tokenIds = []
            for (let i = 0; i < balanceOfUser; i++) {
                tokenIds.push((await nftContract.tokenOfOwnerByIndex(address, i)).toNumber())
            }
            setUserNFts(tokenIds);
        } catch (e) {
            console.log(e)
        }
       
    }

    async function getUserStakedNFTs() {
        try{
            setUserStakedNFts((await stakeContract.getStakedNFTsOfUser(address)).map(t => t.toNumber()))
        } catch (e) {
            console.log(e)
        }
    }

    async function getUserNFTApprovals() {
        try{
            let approvals = {};
            for (let i = 0; i < userNfts.length; i++) {
                let approvedAddress = await nftContract.getApproved(userNfts[i]);
                approvals[userNfts[i]] = approvedAddress
            }
            setUserNftApprovals(approvals)
        } catch (e) {
            console.log(e)
        }
    }

    async function getUserContractApprovals() {
        try{
            setUserContractApproval(await nftContract.isApprovedForAll(address, stakeContract.address))
        } catch (e) {
            console.log(e)
        }
    }
    
    async function getClaimableLp() {
        let addresses = allPairs.map(pair => pair.lp);        
        let totalClaimable = await stakeContract.releasableAll(addresses, userStakedNfts);
        setClaimableLp(totalClaimable);
    }

    useEffect(() => {
        if (nftContract && stakeContract && address) {
            getUserNFTs();
            getUserStakedNFTs();
            getUserContractApprovals();
        }
    }, [nftContract, stakeContract, address])

    useEffect(() => {
        if (userNfts && userNfts.length > 0) {
            getUserNFTApprovals();
        }
    }, [userNfts])

    useEffect(() => {        
        if (userStakedNfts && userStakedNfts.length > 0 && allPairs && allPairs.length > 0) {
            getClaimableLp();
        }
    }, [userStakedNfts, allPairs])

    return [
        userNfts,
        userStakedNfts,
        userNftApprovals,
        userContractApproval,
        getUserContractApprovals,
        getUserNFTs,
        getUserStakedNFTs,
        claimableLp,
        getClaimableLp
    ]
}
