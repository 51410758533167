import PropTypes from "prop-types";

const InputRadio = ({ name = "", label = "", options = [], isDisabled, useBaseClass=true, onChange }) => {
  return (
    <div className={useBaseClass ? `form-element-radio` : null}>
      <label>{label}</label>
      <div className="form-element-radio-options">
        {options.map((option) => (
          <label
            htmlFor={option.value}
            key={option.value}
            className="form-element-radio-input"
          >
            <input
              type="radio"
              id={option.value}
              name={name}
              value={option.value}
              disabled={isDisabled}
              onChange={onChange}
            />
            <div>{option.label}</div>
          </label>
        ))}
      </div>
    </div>
  );
};

export default InputRadio;

InputRadio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      name: PropTypes.string,
      value: PropTypes.string.isRequired,
    }).isRequired
  ),
};
