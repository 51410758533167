import { useState } from "react";
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";

import LPAbi from "../abis/LP.json";
import STAKEAbi from "../abis/STAKE.json";
import ERC20Abi from "../abis/ERC20.json";
import ERC721Abi from "../abis/ERC721.json";
import UNIPAIRAbi from "../abis/UNIPAIR.json";

import Config from "../config";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: Config.NETWORK.INFURA_ID,
    },
  }
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
  disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});

export default function useWeb3() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [address, setAddress] = useState(null);
  const [contracts, setContracts] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isCorrectChain, setIsCorrectChain] = useState(undefined);
  const [triedAutoConnecting, setTriedAutoConnecting] = useState(null);
  const [chainId, setChainId] = useState("56")

  async function initiateWeb3(proxy) {
    const _provider = new ethers.providers.Web3Provider(proxy);

    _provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      setIsConnected(false);
    });

    const _signer = _provider.getSigner();
    const _address = await _signer.getAddress();
    let { chainId } = await _provider.getNetwork();
    let _isCorrectChainId = true;
    chainId = chainId.toString();
    setChainId(chainId)

    let acceptedChains = ["56", "1", "1337", "137", "25"];
    if (
        !acceptedChains.includes(chainId)
    ) {
        alert("Chain not supported. Please connect to BSC, ETH, MATIC or CRO chains.");
        //throw new Error("Chain not supported");
    }

    let _contractLP;
    let _contractStake;
    let _contractNft;
    let _contracts = {}

    _contractLP = new ethers.Contract(Config.CONTRACTS[chainId].LP, LPAbi, _provider);
    _contractStake = new ethers.Contract(Config.CONTRACTS[chainId].STAKE, STAKEAbi, _provider);
    _contractNft = new ethers.Contract(Config.CONTRACTS[chainId].NFT, ERC721Abi, _provider);
    
    _contracts["LP"] = _contractLP;
    _contracts["STAKE"] = _contractStake;
    _contracts["NFT"] = _contractNft;

    setProvider(_provider);
    setSigner(_signer);
    setAddress(_address);
    setContracts(_contracts);
    setIsConnected(true);
    setIsCorrectChain(_isCorrectChainId);
  }

  async function tryAutoConnect() {
    try {
      let { cachedProvider } = web3Modal;
      let cachedFromStorage = JSON.parse(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"));

      if (cachedProvider && cachedProvider !== "") {
        await initiateWeb3(await web3Modal.connectTo(cachedProvider));
        return true;
      } else if (cachedFromStorage && cachedFromStorage !== "") {
        await initiateWeb3(await web3Modal.connectTo(cachedFromStorage));
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  async function connect() {
    setIsConnecting(true);
    try {
      // await web3Modal.clearCachedProvider();
      let _proxy = await web3Modal.connect();
      await initiateWeb3(_proxy);
    } catch (e) {
      if (e && e.message) alert(`Connect error ${e.message}`);
    }
    setIsConnecting(false);
  }

  function getERC20Contract(address) {
    return new ethers.Contract(address, ERC20Abi, provider);
  }

  function getPairContract(address) {
    return new ethers.Contract(address, UNIPAIRAbi, provider);
  }


  return [
    provider,
    signer,
    address,
    contracts,
    isConnected,
    isCorrectChain,
    tryAutoConnect,
    triedAutoConnecting,
    setTriedAutoConnecting,
    connect,
    isConnecting,
    getERC20Contract,
    getPairContract,
    chainId
  ];
}
