import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useWallet } from "../../../context/MyWalletProvider";
import Loader from "../../../components/Loader";
import StackCard from "./StackCard";

const Stack = ({
    cards = [],
    title,
    name,
    loading = true,
    checkCard,
    selectAll,
    isApproved,
    approveAll,
    stake,
    unstake,
}) => {
    const [selectedAll, setSelectedAll] = useState(false);

    const onCheckAll = useCallback(() => {
        selectAll(name, !selectedAll);
        setSelectedAll((curr) => !curr);
    }, [name, selectAll, selectedAll]);

    const onStakeAction = async () => {
        let ids = cards.filter((c) => c.selected).map((c) => c.number);
        if (name === "unstacked") {
            if (!isApproved) await approveAll();
            else await stake(ids);
        } else {
            await unstake(ids);
        }
        setSelectedAll(false);
    };

    if (loading)
        return (
            <div className="stack">
                <Loader />
            </div>
        );

    return (
        <div className="stack">
            <div className="stack-header">
                {title && <h3 className="my-wallet-header">{title}</h3>}
                <div className="stack-cards-number">{cards.length >= 0 ? cards.length : 0}</div>
                <div class="stack-buttons">
                    <button
                        className={`stack-button ${selectedAll ? "checked" : ""}`}
                        onClick={onCheckAll}
                        style={{ marginRight: "10px" }}
                    >
                        {selectedAll ? "Unselect All" : "Select All"}
                    </button>
                    <button
                        className={`stack-button ${selectedAll ? "checked" : ""}`}
                        onClick={onStakeAction}
                    >
                        {name === "unstacked" ? (!isApproved ? "Approve" : "Stake") : "Unstake"}
                    </button>
                </div>
            </div>
            <div className="stack-cards">
                {cards.map((card) => (
                    <StackCard key={card.id} stackName={name} checkCard={checkCard} {...card} />
                ))}
            </div>
        </div>
    );
};

export default Stack;

Stack.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string.isRequired,
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            number: PropTypes.number.isRequired,
            image: PropTypes.string,
            selected: PropTypes.bool,
        })
    ),
    loading: PropTypes.bool,
    checkCard: PropTypes.func,
    selectAll: PropTypes.func,
};
