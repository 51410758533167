import { useState, useEffect } from "react";
import { ethers } from "ethers";

import Config from "../config";

export default function useStake(
    nftContract,
    stakeContract,
    signer,
    getUserContractApprovals,
    getUserNFTs,
    getUserStakedNFTs,
    stakedNfts,
    allPairs,
    getClaimableLp
) {
    async function approve() {
        try {
            let tx = await nftContract.connect(signer).setApprovalForAll(stakeContract.address, true)
            await tx.wait()
            await getUserContractApprovals()
        } catch (e) {
            console.log(e)
        }
        
    }

    async function stake(ids) {
        try {
            let tx = await stakeContract.connect(signer).stakeMany(ids)
            await tx.wait()
            setTimeout(async () => {
                await getUserStakedNFTs();
                await getUserNFTs();               
            }, 300);            
        } catch (e) {
            console.log(e)
        }
    }

    async function unstake(ids) {
        try {
            let tx = await stakeContract.connect(signer).unstakeMany(ids)
            await tx.wait()
            setTimeout(async () => {                
                await getUserStakedNFTs();
                await getUserNFTs();
            }, 300);   
        } catch (e) {
            console.log(e)
        }
    }   

    async function claimLp() {
        try {
            let addresses = allPairs.map(pair => pair.lp);
            console.log(stakedNfts)
            let tx = await stakeContract.connect(signer).claimLpTokens(addresses, stakedNfts);
            await tx.wait();
            await getClaimableLp();
        } catch (e) {
            console.log(e)
        }        
    }


    return [
        approve,
        stake,
        unstake,
        claimLp
    ]
}
