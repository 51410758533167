import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";

import { DUMMY_BALANCE, DUMMY_CARDS } from "../constants/dummy";

const MyWalletContext = createContext({
  balance: 0,
  claimable: 0,
  unstacked: [],
  stacked: [],
  balanceLoading: true,
  cardsLoading: true,
  onAddToMetamask: () => {},
  onClaim: () => {},
  checkCard: (stackName, id, checked) => {},
  selectAll: (stackName, checked) => {},
});

export const useWallet = () => {
  const context = useContext(MyWalletContext);
  if (!context) {
    throw new Error("useWallet must be used within a MyWalletProvider");
  }
  return context;
};

const MyWalletProvider = ({ children }) => {
  const [balance, setBalance] = useState(0);
  const [claimable, setClaimable] = useState(0);
  const [unstacked, setUnstacked] = useState([]);
  const [stacked, setStacked] = useState([]);
  const [balanceLoading, setBalanceLoading] = useState(true);
  const [cardsLoading, setCardsLoading] = useState(true);

  // dummy API call to return cards data
  const getCardsData = useCallback(async () => {
    setCardsLoading(true);
    try {
      const data = await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(DUMMY_CARDS);
        });
      }, 2000);
      if (data) {
        setUnstacked(
          data.unstacked.map((card) => ({ ...card, selected: false }))
        );
        setStacked(data.stacked.map((card) => ({ ...card, selected: false })));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCardsLoading(false);
    }
  }, []);

  // dummy API call to return balance data
  const getBalanceData = useCallback(async () => {
    setBalanceLoading(true);
    try {
      const data = await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(DUMMY_BALANCE);
        });
      }, 2000);
      if (data) {
        setBalance(data.balance);
        setClaimable(data.claimable);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBalanceLoading(false);
    }
  }, []);

  useEffect(() => {
    getCardsData();
    getBalanceData();
  }, [getCardsData, getBalanceData]);

  const onAddToMetamask = useCallback(() => {
    console.warn("TODO: Add to Metamask");
  }, []);

  const onClaim = useCallback(() => {
    console.warn("TODO: Claim");
  }, []);

  const checkCard = useCallback((stackName, id, checked) => {
    switch (stackName) {
      case "unstacked":
        setUnstacked((prev) => {
          return prev.map((card) => {
            if (card.id === id) {
              return { ...card, selected: checked };
            }
            return card;
          });
        });
        break;
      case "stacked":
        setStacked((prev) => {
          return prev.map((card) => {
            if (card.id === id) {
              return { ...card, selected: checked };
            }
            return card;
          });
        });
        break;
      default:
        break;
    }
  }, []);

  const selectAll = useCallback((stackName, checked) => {
    switch (stackName) {
      case "unstacked":
        setUnstacked((prev) => {
          return prev.map((card) => {
            return { ...card, selected: checked };
          });
        });
        break;
      case "stacked":
        setStacked((prev) => {
          return prev.map((card) => {
            return { ...card, selected: checked };
          });
        });
        break;
      default:
        break;
    }
  }, []);

  return (
    <MyWalletContext.Provider
      value={{
        balance,
        balanceLoading,
        cardsLoading,
        checkCard,
        claimable,
        onAddToMetamask,
        onClaim,
        selectAll,
        stacked,
        unstacked,
      }}
    >
      {children}
    </MyWalletContext.Provider>
  );
};

export default MyWalletProvider;

MyWalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
