import PropTypes from "prop-types";
import { GrCertificate } from "react-icons/gr";

const CertifiedToken = ({ name = "", onClick }) => {
  return (
    <div className="certified-token" onClick={onClick}>
      <GrCertificate stroke="#ddd" />
      <div className="certified-token-label">{name}</div>
    </div>
  );
};

export default CertifiedToken;

CertifiedToken.propTypes = {
  name: PropTypes.string,
};
