import { useState, useEffect } from "react";
import { ethers } from "ethers";

import Config from "../config";

export default function useLock(
    lpContract,
    signer,
    getERC20Contract,
    getUserLockedTokens,
    getAllLockedTokens
) {
    async function getApproval(lpAddress) {
        let signerAddress = await signer.getAddress();
         try {
            let lpTokenContract = getERC20Contract(lpAddress);
            let allowance = await lpTokenContract.allowance(signerAddress, lpContract.address);
             return allowance;
        } catch (e) {
            console.log(e)
        }
    }

    async function approveLock(
        lpAddress,
        amount
    ) {
        try {
            let lpTokenContract = getERC20Contract(lpAddress);
            let tx = await lpTokenContract.connect(signer).approve(lpContract.address, amount);
            await tx.wait();  
        } catch (e) {
            console.log(e)
        }
    }

    async function lock(
        lpAddress,
        amount,
        unlockDate,
        feeInBnb,
        withdrawer,
        callData
    ) {
        try {
            let tx = await lpContract.connect(signer).lockLPToken(
                lpAddress,
                amount,
                unlockDate,
                feeInBnb,
                withdrawer,
                callData
            );
            await tx.wait();
            await getUserLockedTokens();
            await getAllLockedTokens();
        } catch (e) {
            console.log(e)
        }
    }

    async function reLock(
        lpAddress,
        lockIndex,
        lockId,
        unlockDate
    ) {
        try {
            let tx = await lpContract.connect(signer).relock(
                lpAddress,
                lockIndex,
                lockId,
                unlockDate
            );
            await tx.wait();
            await getUserLockedTokens();
            await getAllLockedTokens();
        } catch (e) {
            console.log(e)
        }
    }

    async function withdraw(
        lpAddress,
        lockIndex,
        lockId,
        amount
    ) {
        try {
            let tx = await lpContract.connect(signer).withdraw(
                lpAddress,
                lockIndex,
                lockId,
                amount
            );
            await tx.wait();
            await getUserLockedTokens();
            await getAllLockedTokens();
        } catch (e) {
            console.log(e)
        }
    }

    async function addToLock(
        lpAddress,
        lockIndex,
        lockId,
        amount,
        feeInBnb,
        extraData
    ) {
        try {
            let tx = await lpContract.connect(signer).incrementLock(
                lpAddress,
                lockIndex,
                lockId,
                amount,
                feeInBnb,
                extraData
            );
            await tx.wait();
            await getUserLockedTokens();
            await getAllLockedTokens();
        } catch (e) {
            console.log(e)
        }
    }

    async function transferLockOwnership(
        lpAddress,
        lockIndex,
        lockId,
        newOwner
    ) {
        try {
            let tx = await lpContract.connect(signer).transferLockOwnership(
                lpAddress,
                lockIndex,
                lockId,
                newOwner
            );
            await tx.wait();
            await getUserLockedTokens();
            await getAllLockedTokens();
        } catch (e) {
            console.log(e)
        }
    }

    return [
        approveLock,
        lock,
        reLock,
        withdraw,
        addToLock,
        transferLockOwnership,
        getApproval
    ]
}
