import { useWallet } from "../../context/MyWalletProvider";
import withHeader from "../../hoc/withHeader";
import { useState, useEffect, useCallback } from "react";
import useNftData from "../../hooks/useNftData";
import useWeb3 from "../../hooks/useWeb3";
import useStake from "../../hooks/useStake";
import useLpData from "../../hooks/useLpData";

import WalletCard from "./components/WalletCard";
import Stack from "./components/Stack";

import "../../styles/MyWallet.css";
import { ethers } from "ethers";

import Config from "../../config";

const MyWallet = () => {
    const [unstakedCards, setUnstakedCards] = useState([]);
    const [stakedCards, setStakedCards] = useState([]);

    // const {
    //     balance,
    //     onAddToMetamask,
    //     claimable,
    //     onClaim,
    //     stacked,
    //     unstacked,
    //     cardsLoading,
    // } = useWallet();

    const [
        provider,
        signer,
        address,
        contracts,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect,
        isConnecting,
        getERC20Contract,
        getPairContract,
        chainId
      ] = useWeb3();

      const [
        allLocks,
        userLocks,
        lockAggregates,
        fetchLockAggregate,
        fetchLpTokenData,
        fetchUserLpBalance
      ] = useLpData(
        contracts.LP,
        address,
        getERC20Contract,
        getPairContract
      )

    const [
        userNfts,
        userStakedNfts,
        userNftApprovals,
        userContractApproval,
        getUserContractApprovals,
        getUserNFTs,
        getUserStakedNFTs,
        claimableLp,
        getClaimableLp
    ] = useNftData(
        contracts.NFT,
        contracts.STAKE,
        address,
        allLocks
    )

    const [
        approve,
        stake,
        unstake,
        claimLp
    ] = useStake(
        contracts.NFT,
        contracts.STAKE,
        signer,
        getUserContractApprovals,
        getUserNFTs,
        getUserStakedNFTs,
        userStakedNfts,
        allLocks,
        getClaimableLp
    )

    useEffect(() => {
        if (userNfts) {
            setUnstakedCards(userNfts.map(nft => ({
                img: `https://mint.grimacebsc.com/nft/${nft}.png`,
                number: nft,
                id: nft.toString(),
                selected: false
            })))
        }
    }, [userNfts])

    useEffect(() => {
        if (userStakedNfts) {
            setStakedCards(userStakedNfts.map(nft => ({
                img: `https://mint.grimacebsc.com/nft/${nft}.png`,
                number: nft,
                id: nft.toString(),
                selected: false
            })))
        }
    }, [userStakedNfts])

    useEffect(() => {
        if (chainId !== "56") {
            alert(`Staking not supported on ${Config.NETWORK.CHAIN_ID_TO_NAME[chainId]}`)
            window.open("/", "_self")
        }
    }, [chainId])

    const checkCard = (stackName, id, checked) => {
        switch (stackName) {
          case "unstacked":
            setUnstakedCards((prev) => {
              return prev.map((card) => {
                if (card.id === id) {
                  return { ...card, selected: checked };
                }
                return card;
              });
            });
            break;
          case "stacked":
            setStakedCards((prev) => {
              return prev.map((card) => {
                if (card.id === id) {
                  return { ...card, selected: checked };
                }
                return card;
              });
            });
            break;
          default:
            break;
        }
      };
    
      const selectAll = (stackName, checked) => {      
        switch (stackName) {
          case "unstacked":
            setUnstakedCards((prev) => {
              return prev.map((card) => {
                return { ...card, selected: checked };
              });
            });
            break;
          case "stacked":
            setStakedCards((prev) => {
              return prev.map((card) => {
                return { ...card, selected: checked };
              });
            });
            break;
          default:
            break;
        }
      };

  return (
    <div className="my-wallet">
        <div className="grimace-page-header"> 
            <img src="/b98edf33-1760-4b6f-a2c4-ba5d661faaa4.png" alt="img" style={{height: "150px"}} />
            {
                !isConnected ?
                    <button className="crimace-swipe-button" onClick={connect}>Connect Wallet</button>
                :
                    null
            }
        </div>
      <div className="my-wallet-balance">
        <WalletCard
          buttonText="Claim LP tokens"
          coin="LP Tokens"
          number={claimableLp === 0 ? claimableLp :  parseFloat(ethers.utils.formatEther(claimableLp)).toString()}
          onClick={claimLp}
        />
      </div>
      <div className="stacking-container">
        <Stack
          loading={isConnected && !userNfts}
          cards={unstakedCards}
          title="Unstaked"
          name="unstacked"
          checkCard={checkCard}
          selectAll={selectAll}
          isApproved={userContractApproval}
          approveAll={approve}
          stake={stake}
          unstake={unstake}
        />
        <Stack
          loading={isConnected && !userStakedNfts}
          cards={stakedCards}
          title="Staked"
          name="stacked"
          checkCard={checkCard}
          selectAll={selectAll}
          isApproved={userContractApproval}
          approveAll={approve}
          stake={stake}
          unstake={unstake}
        />
      </div>
    </div>
  );
};

export default withHeader(MyWallet);
