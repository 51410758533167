export const DUMMY_BALANCE = {
  balance: 100,
  claimable: 50,
};

export const DUMMY_CARDS = {
  unstacked: [
    {
      img: "https://mint.grimacebsc.com/nft/1.png",
      number: 1,
      id: "1",
    },
    {
      img: "https://mint.grimacebsc.com/nft/2.png",
      number: 2,
      id: "2",
    },
    {
      img: "https://mint.grimacebsc.com/nft/3.png",
      number: 3,
      id: "3",
    },
    {
      img: "https://mint.grimacebsc.com/nft/4.png",
      number: 4,
      id: "4",
    },
  ],
  stacked: [
    {
      img: "https://mint.grimacebsc.com/nft/5.png",
      number: 5,
      id: "5",
    },
    {
      img: "https://mint.grimacebsc.com/nft/6.png",
      number: 6,
      id: "6",
    },
  ],
};
