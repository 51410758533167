import MyWalletProvider from "../../context/MyWalletProvider";
import MyWallet from "./MyWallet";

export default function MyWalletPage() {
  return (
    <MyWalletProvider>
      <MyWallet />
    </MyWalletProvider>
  );
}
