import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GrimaceForm from "./pages/crimace-swipe-form";
import MyWallet from "./pages/my-wallet";
import PageNotFound from "./pages/404";

import "./styles/index.css";
import "./styles/normalize.css";

function App() {
  return (
    <Router>
      <Routes>        
        <Route path="/" element={<GrimaceForm />} />
        <Route path="/staking" element={<MyWallet />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
