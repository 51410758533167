import PropTypes from "prop-types";
import { useCallback } from "react";
import { useWallet } from "../../../context/MyWalletProvider";

const StackCard = ({ number, img, id, selected, stackName, checkCard }) => {
  //const { checkCard } = useWallet();

  const handleCardClick = useCallback(
    (e) => {
      checkCard(stackName, id, e.target.checked);
    },
    [id, stackName, checkCard]
  );

  return (
    <label className={`stack-card ${selected ? "checked" : ""}`}>
      <img src={img} alt="card" />
      <span>#{number} </span>
      <input
        checked={selected}
        onChange={handleCardClick}
        type="checkbox"
        hidden
      />
    </label>
  );
};

export default StackCard;

StackCard.propTypes = {
  number: PropTypes.number.isRequired,
  img: PropTypes.string,
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  stackName: PropTypes.string.isRequired,
  checkCard: PropTypes.func
};
