const Config = {
    DATA: {
        FEE: {
            "1": {
                currency: "ETH",
                amount: "0.021"
            },
            "25": {
                currency: "CRO",
                amount: "2540"
            },
            "56": {
                currency: "BNB",
                amount: "0.1"
            },
            "1337": {
                currency: "ETH",
                amount: "0.1"
            },
            "137": {
                currency: "MATIC",
                amount: "32.28"
            }
        }
    },
    CONTRACTS: {
        "1337": {
            LP: "0x6fDA2FA1581beF7ad3481770aa1C70b32129C1Ca",
            STAKE: "0x7b4DEBBD49c2F55BEcFE4b31700209CEb3736f34",
            NFT: "0xC67a0b52E4a769D092FF1FF7238d3e75bEB752d4"
        },
        "1": {
            LP: "0x81c1f913e361EA7E9aF7FE453a3Ad184Adf16296",
            STAKE: "0x81c1f913e361EA7E9aF7FE453a3Ad184Adf16296",
            NFT: "0x81c1f913e361EA7E9aF7FE453a3Ad184Adf16296"
        },
        "56": {
            LP: "0x6A9aF3BEfCAcc9b1E0314c69843Be3c9d1CE5bf4",
            STAKE: "0x81c1f913e361EA7E9aF7FE453a3Ad184Adf16296",
            NFT: "0x4aAA5684227A1912dFa81642d67f436aE1a2011C"
        },
        "25": {
            LP: "0x81c1f913e361EA7E9aF7FE453a3Ad184Adf16296",
            STAKE: "0x81c1f913e361EA7E9aF7FE453a3Ad184Adf16296",
            NFT: "0x81c1f913e361EA7E9aF7FE453a3Ad184Adf16296"
        },
        "137": {
            LP: "0x81c1f913e361EA7E9aF7FE453a3Ad184Adf16296",
            STAKE: "0x81c1f913e361EA7E9aF7FE453a3Ad184Adf16296",
            NFT: "0x81c1f913e361EA7E9aF7FE453a3Ad184Adf16296"
        }
    },
    NETWORK: {
        CHAIN_ID_TO_NAME: {
            "1": "mainnet",
            "3": "ropsten",
            "4": "rinkeby",
            "1337": "localhost",
            "56": "bsc",
            "25": "cronos",
            "137": "matic"
        },
        INFURA_ID: "",
    },
    ERRORS: {
        MESSAGES: []
    }
}

export default Config
